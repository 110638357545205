import React from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import {Link} from "react-router-dom";
import {t} from "i18next";

function Header() {
    return (
        <header>
            <div className="container">
                <Link to={"/"} className={"logo"}>
                    <Logo className={"logo-img"}/>
                    {t('app_title')}
                </Link>
            </div>
        </header>
    );
}

export default Header;
