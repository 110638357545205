import React, { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from "../config/firebase/firebase-analytics";

export function useTrackTimeOnPage(timeout = 30000) {
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            const timer = setTimeout(() => {
                logEvent(analytics, 'time_on_page', {
                    description: 'User spent significant time on page',
                    duration: `${timeout / 1000} seconds`
                });
            }, timeout);

            return () => clearTimeout(timer);
        }
    }, [timeout]);
}

export function useTrackScrollDepth() {
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            const handleScroll = () => {
                const scrolled = window.scrollY;
                const height = document.documentElement.scrollHeight - window.innerHeight;
                const scrolledPercentage = (scrolled / height) * 100;

                if (scrolledPercentage > 50) {
                    logEvent(analytics, 'scroll_50', { page: window.location.pathname });
                }
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, []);
}

export function useTrackPageExit() {
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            const handleBeforeUnload = () => {
                logEvent(analytics, 'page_exit', {
                    page: window.location.pathname,
                    timestamp: new Date().toISOString()
                });
            };

            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, []);
}

export const AnalyticsProvider = ({ children }) => {
    useTrackTimeOnPage(30000);
    useTrackScrollDepth();
    useTrackPageExit();

    return <>{children}</>;
};
