import {initializeApp} from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCjp_aCc8ipDncpPc0rli1teH_rja3097o",
    authDomain: "accounts-app-7c1ba.firebaseapp.com",
    projectId: "accounts-app-7c1ba",
    storageBucket: "accounts-app-7c1ba.appspot.com",
    messagingSenderId: "456923963112",
    appId: "1:456923963112:web:8ba470709125b775c4b35d",
    measurementId: "G-KERTE1N2MM"
};

export const app = initializeApp(firebaseConfig);