import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '../../config/firebase/firebase-analytics';
import { t } from "i18next";
import { logEvent } from "firebase/analytics";
import { useLoading } from "../../components/LoadingScreen";
import createAlert from "../../components/AlertComponent";
import currencyData from "../../config/currency-country.json";
import ApiService from "../../components/ApiService";
import axios from "axios";

function CreateAccount() {
    const [accountName, setAccountName] = useState('');
    const [actors, setActors] = useState({0: {name: ''}, 1: {name: ''}});
    const [actorIdCounter, setActorIdCounter] = useState(2);
    const navigate = useNavigate();
    const actorsRef = useRef({});
    const [focusNewActor, setFocusNewActor] = useState(false);
    const { showLoading, hideLoading } = useLoading();
    const [selectedLocale, setSelectedLocale] = useState('');

    useEffect(() => {
        axios.get('https://ipapi.co/json/').then((response) => {
            const {languages} = response.data;
            const userLocale = languages || navigator.language;
            const matchingLocale = Object.keys(currencyData).find(locale => locale.startsWith(userLocale));
            setSelectedLocale(matchingLocale || 'en-US');
        });
    }, []);

    const handleLocaleChange = (event) => {
        setSelectedLocale(event.target.value);
    };

    const localeOptions = Object.entries(currencyData).map(([locale, { currencyCode, countryName }]) => ({
        locale: locale,
        description: `${countryName} (${currencyCode})`
    }));

    const handleAddActor = () => {
        const newActorId = actorIdCounter;
        setActors(prevActors => ({
            ...prevActors,
            [newActorId]: { name: '' }
        }));
        setActorIdCounter(newActorId + 1);
        setFocusNewActor(true);
    };

    useEffect(() => {
        if (focusNewActor) {
            const lastActorId = actorIdCounter - 1;
            if (actorsRef.current[lastActorId]) {
                actorsRef.current[lastActorId].focus();
                setFocusNewActor(false);
            }
        }
    }, [actors, focusNewActor, actorIdCounter]);

    const handleNameChange = (id, newName) => {
        const updatedActors = {
            ...actors,
            [id]: { ...actors[id], name: newName }
        };
        setActors(updatedActors);
    };

    const handleDeleteActor = (actorId) => {
        const {[actorId]: _, ...remainingActors} = actors;
        setActors(remainingActors);
    };

    const handleCreateAccount = async (e) => {
        const apiService = new ApiService(process.env.REACT_APP_ACCOUNT_ENDPOINT);
        e.preventDefault();
        showLoading('action');
        try {
            let currencySelectedData = {
                locale: selectedLocale,
                currency: currencyData[selectedLocale]['currencyCode']
            }

            const accountData = {
                accountName: accountName,
                currency: currencySelectedData,
                date: new Date(),
                actors: actors
            };

            const newAccount = await apiService.postData('account/',accountData);

            logEvent(analytics, 'account_created_success', { description: 'User create a new account' });
            createAlert(t('new_document_success'), "success", 3000);
            navigate(`/a/${newAccount.id}`);
        } catch (error) {
            console.error(t('error_adding_document'), error);
            logEvent(analytics, 'account_created_error', { description: error });
            createAlert(t('error_adding_document_msj'), "warning", 3000);
        } finally {
            hideLoading();
        }
    };

    return (
        <div className="container">
            <div className="my-5">
                <div className="card w-100">
                    <div className="card-body">
                        <form onSubmit={handleCreateAccount}>
                            <div className="mb-3">
                                <label htmlFor="account_name" className="form-label">{t('account_name')}</label>
                                <input
                                    id="account_name"
                                    className="form-control"
                                    placeholder={t('account_name_ej')}
                                    value={accountName}
                                    onChange={e => setAccountName(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="currency-select" className="form-label">{t('default_currency')}</label>
                                <select value={selectedLocale} className="form-control" id={"currency-select"}
                                        onChange={handleLocaleChange}>
                                    {localeOptions.map(({locale, description}) => (
                                        <option key={locale} value={locale}>
                                            {description}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <hr className="my-3"/>
                            <div>
                                <label className="form-label">{t('who_participates')}</label>
                                {Object.keys(actors).map((actorId) => (
                                    <div key={actorId} className="mb-2 input-group">
                                        <input
                                            ref={el => actorsRef.current[actorId] = el}
                                            type="text"
                                            className="form-control"
                                            value={actors[actorId].name}
                                            onChange={(e) => handleNameChange(actorId, e.target.value)}
                                            placeholder={t('actor_name_ej')}
                                            required
                                        />
                                        {Object.keys(actors).length > 1 && (
                                            <button className="btn btn-outline-danger" type="button"
                                                    onClick={() => handleDeleteActor(actorId)}>
                                                <i className="fa-solid fa-trash"></i>
                                            </button>
                                        )}
                                    </div>
                                ))}
                                <small className="text-muted my-1">{t('who_participates_msj')}</small>
                                <button type="button" className="btn btn-primary w-100 mt-2"
                                        onClick={handleAddActor}>{t('new_actor')}</button>
                            </div>
                            <hr className="my-3"/>
                            <div>
                                <button type="submit" className="btn btn-success w-100">{t('create_account')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateAccount;
