import React from 'react';
import {Link} from 'react-router-dom';
import {t} from "i18next";
import LanguageSwitcher from "../components/LanguageSwitcher";
import { ReactComponent as Logo } from '../assets/logo.svg';

function Footer() {
    return (
        <footer>
            <div className="container pb-5">
                <div className="align-items-center d-flex justify-content-between w-100 mt-4">
                    <div className={"d-flex flex-column gap-2"}>
                        <Link to={"https://desveladev.com/"}
                              className={"btn-link text-decoration-none"}>{t('creator_page')} <i
                            className="fa-solid fa-angle-right link"></i></Link>
                        <Link to="/privacy_policy" className="btn-link text-decoration-none">{t('privacy_policy')} <i
                            className="fa-solid fa-angle-right link"></i></Link>
                    </div>
                    <div>
                        <LanguageSwitcher />
                    </div>
                </div>
                <div className="text-center mt-5">
                    <Logo className={"logo-img"}/>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
