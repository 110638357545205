export function settlementCalculation(payments) {
    let balances = {};

    payments.forEach(payment => {
        const amountPerPerson = payment.amount / payment.beneficiaries.length;
        payment.beneficiaries.forEach(b => {
            balances[b] = (balances[b] || 0) + amountPerPerson;
        });
        const payerAmount = payment.amount / payment.payers.length;
        payment.payers.forEach(p => {
            balances[p] = (balances[p] || 0) - payerAmount;
        });
    });

    let receivers = [], payers = [];
    Object.entries(balances).forEach(([person, balance]) => {
        if (balance > 0) payers.push({id: person, amount: balance});
        else if (balance < 0) receivers.push({id: person, amount: -balance});
    });

    receivers.sort((a, b) => a.amount - b.amount);
    payers.sort((a, b) => a.amount - b.amount);

    let transactions = [];
    receivers.forEach(receiver => {
        while (receiver.amount > 0) {
            const payer = payers.find(p => p.amount > 0);
            if (!payer) break;

            let amount = Math.min(receiver.amount, payer.amount);
            transactions.push({
                receiverId: receiver.id,
                amount: parseFloat(amount.toFixed(2)),
                payerId: payer.id
            });

            receiver.amount -= amount;
            payer.amount -= amount;
            receiver.amount = parseFloat(receiver.amount.toFixed(2));
            payer.amount = parseFloat(payer.amount.toFixed(2));
        }
    });

    return transactions;
}

export function calculateTotals(payments, actors) {
    let totalsTemp = {};

    // Inicializar cada actor en totalsTemp
    Object.keys(actors).forEach(actorId => {
        totalsTemp[actorId] = { paid: 0, shouldPay: 0 };
    });

    payments.forEach(payment => {
        const paymentAmount = parseFloat(payment.amount) || 0;
        const numPayers = payment.payers.length;
        const numBeneficiaries = payment.beneficiaries.length;

        payment.payers.forEach(payer => {
            if (totalsTemp[payer]) {
                totalsTemp[payer].paid += paymentAmount / numPayers;
            }
        });

        payment.beneficiaries.forEach(beneficiary => {
            if (totalsTemp[beneficiary]) {
                totalsTemp[beneficiary].shouldPay += paymentAmount / numBeneficiaries;
            }
        });
    });

    Object.keys(actors).forEach(actorId => {
        if (totalsTemp[actorId]) {
            totalsTemp[actorId].balance = totalsTemp[actorId].shouldPay - totalsTemp[actorId].paid;
        }
    });

    return totalsTemp;
}

export const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        minute = '' + d.getMinutes();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;

    return `${year}-${month}-${day}T${hour}:${minute}`;
}


