import React, {useState, useEffect} from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import {analytics} from '../../config/firebase/firebase-analytics';
import {t} from "i18next";
import {logEvent} from "firebase/analytics";
import LoadingScreen, {useLoading} from "../../components/LoadingScreen";
import createAlert from "../../components/AlertComponent";
import HelpButton from "../../components/HelpButton";
import ApiService from "../../components/ApiService";

function Account() {
    const {account_id} = useParams();
    const navigate = useNavigate();
    const [account, setAccount] = useState(null);
    const [isDemo, setIsDemo] = useState(false);
    const [payments, setPayments] = useState([]);
    //const [totals, setTotals] = useState({});
    const [settlements, setSettlements] = useState([]);
    const [loadingPayments, setLoadingPayments] = useState(true);
    const {showLoading, hideLoading} = useLoading();

    useEffect(() => {
        const accountApiService = new ApiService(process.env.REACT_APP_ACCOUNT_ENDPOINT);
        const paymentApiService = new ApiService(process.env.REACT_APP_PAYMENT_ENDPOINT);
        let isMounted = true;

        const fetchAccount = async () => {
            try {
                const accountData = await accountApiService.getData('account/' + account_id)
                setAccount(accountData);
                const accountIsDemo = accountData.isDemo;
                setIsDemo(accountIsDemo);

                //setTotals(accountData.totals || {});
                setSettlements(accountData.settlements || {});
                const paymentsData = await paymentApiService.getData('payment/account/' + account_id);
                if (paymentsData) {
                    setLoadingPayments(true);
                    setPayments(paymentsData);
                } else {
                    setPayments([]);
                }
                setLoadingPayments(false);
            } catch (error) {
                console.log(t('no_such_document'));
                console.error('Error al cargar los datos:', error);
                navigate('/');
            }
        };

        if (isMounted) {
            fetchAccount().catch(error => {
                console.error(t('failed_to_fetch_account', {error: error.message}));
                setLoadingPayments(false);
            });
        }

        return () => {
            isMounted = false;
        };
    }, [account_id, navigate]);


    useEffect(() => {
        if (account && account.accountName) {
            document.title = account.accountName;
        }
    }, [account]);

    const handleCreatePayment = () => {
        localStorage.setItem('account_id', account_id);
        logEvent(analytics, 'go_to_create_payment_account_click', {
            description: 'User clicked the create payment button'
        });
    };

    const handleDeletePayment = async (paymentId) => {
        if (window.confirm(t('confirm_delete_payment'))) {
            showLoading('delete');
            const accountApiService = new ApiService(process.env.REACT_APP_ACCOUNT_ENDPOINT);
            const paymentApiService = new ApiService(process.env.REACT_APP_PAYMENT_ENDPOINT);
            let updatedPayments = [];
            //let updatedTotals = [];
            let newSettlement = [];
            try {
                await paymentApiService.deleteData("payment/" + paymentId);
                updatedPayments = await paymentApiService.getData('payment/account/' + account_id);
                const accountUpdate = await accountApiService.getData('account/' + account_id);
                newSettlement = accountUpdate.settlements;

                logEvent(analytics, 'delete_payment_from_account_success', {
                    description: 'User deleted payment'
                });
                createAlert(t('payment_delete_success'), "success", 3000);
            } catch (error) {
                console.log(error);
                logEvent(analytics, 'delete_payment_from_account_error', {
                    description: error
                });
                createAlert(t('payment_delete_error_msj'), "warning", 3000);
            } finally {
                setPayments(updatedPayments);
                //setTotals(updatedTotals);
                setSettlements(newSettlement);
                hideLoading();
            }
        }
    };


    const handleDeleteAccount = async () => {
        if (window.confirm(t('confirm_delete_account'))) {
            showLoading('delete');
            const apiService = new ApiService(process.env.REACT_APP_ACCOUNT_ENDPOINT);
            try {
                await apiService.deleteData('account/' + account_id);

                logEvent(analytics, 'delete_account_success', {
                    description: 'User deleted account'
                });

                createAlert(t('account_delete_success'), "success", 3000);
                navigate('/');
            } catch (error) {
                logEvent(analytics, 'delete_account_error', {
                    description: error
                });
                createAlert(t('account_delete_error_msj'), "warning", 3000);
            } finally {
                hideLoading();
            }
        }
    };

    if (!account) {
        return <LoadingScreen/>;
    }

    return (
        <>
            <div className="container">
                <div className="d-flex justify-content-between mb-3 align-items-center">
                    <Link to={"/"} className="btn btn-link me-2">
                        <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                    <h1 className="mb-0 me-auto ms-3 start-0">{account.accountName}</h1>
                </div>
                <div className="text-center">
                    <p className={"mb-3"}>{t('remember_share')}</p>
                    {
                        isDemo ? (
                            <button className="btn btn-primary" disabled type="button">{t('create_payment')}</button>
                        ) : (
                            <Link to={"/p/new"} className="btn btn-primary"
                                  onClick={handleCreatePayment}>{t('create_payment')}</Link>
                        )
                    }
                </div>
            </div>
            <hr className="my-3"/>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-12">
                        <div className="mb-3">
                            <h4 className="mb-3">{t('account_settlement')} <HelpButton
                                tooltipText={t('how_work_settlement')}/></h4>
                            <ul className="list-group">
                                {settlements.length > 0 ? (
                                    settlements.map((transaction, index) => (
                                        <li key={index}
                                            className="list-group-item d-flex justify-content-center align-items-center">
                                            <span>{account.actors[transaction.payerId]['name']}</span>
                                            <span className="mx-auto text-success numbers">
                                                <i className="fa-solid fa-money-bills me-3"></i>
                                                {parseInt(transaction.amount).toLocaleString(account.currency['locale'], {
                                                    style: 'currency',
                                                    currency: account.currency['currency']
                                                })}
                                                <i className="fa-solid fa-arrow-right ms-3"></i>
                                            </span>
                                            <span>{account.actors[transaction.receiverId]['name']}</span>
                                        </li>
                                    ))
                                ) : (
                                    <li className="list-group-item text-center">{t('settlement_accounts_success')}</li>
                                )}
                            </ul>
                        </div>
                        <div>
                            <h4 className="mb-3">
                                {t('payments')}<HelpButton tooltipText={t('how_work_payments_list')}/>
                            </h4>
                            <ul className="list-group payments-list">
                                {loadingPayments ? (
                                    <li className="list-group-item text-center"
                                        style={{backgroundColor: 'var(--bs-border-color)'}}>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">{t('loading')}</span>
                                        </div>
                                    </li>
                                ) : (payments.length > 0 ? (
                                        payments.map((payment, index) => (
                                            <Link className="align-items-center d-flex list-group-item payment-item"
                                                  key={index}
                                                  to={"/p/" + payment.id}>
                                                <div className="text-break text-start">
                                                    <p className="m-0">{payment.description}</p>
                                                    <small className="text-muted payers-list d-md-flex d-none">
                                                        {payment.payers.map((payer, subIndex) => (
                                                            <div key={subIndex}
                                                                 className="me-1">{account.actors[payer]['name']}</div>
                                                        ))}
                                                    </small>
                                                </div>
                                                <div className="me-0 ms-auto">
                                                    <p className={"numbers"}>
                                                        {parseInt(payment.amount).toLocaleString(account.currency['locale'], {
                                                            style: 'currency',
                                                            currency: account.currency['currency']
                                                        })}
                                                    </p>
                                                </div>
                                                {
                                                    isDemo ? (
                                                        <button className="btn btn-outline-danger me-0 ms-2 ms-lg-5"
                                                                type="button" disabled>
                                                            <i className="fa-solid fa-trash"></i>
                                                        </button>
                                                    ) : (
                                                        <button className="btn btn-outline-danger me-0 ms-2 ms-lg-5"
                                                                type="button"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleDeletePayment(payment.id).catch(error => {
                                                                        console.error('Failed to delete payment:', error);
                                                                        createAlert(t('payment_delete_error_msj'), "warning", 3000);
                                                                    });
                                                                }}>
                                                            <i className="fa-solid fa-trash"></i>
                                                        </button>
                                                    )
                                                }
                                                <div className="ms-2 px-3 py-2">
                                                    <i className="fa-solid fa-arrow-right text-muted"></i>
                                                </div>
                                            </Link>
                                        ))
                                    ) : (
                                        <li className="list-group-item text-center">{t('without_payments')}</li>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-5 text-center">
                        {
                            isDemo ? (
                                <button className="btn btn-outline-danger" disabled type="button">
                                    {t('delete_account')}
                                </button>
                            ) : (
                                <button onClick={handleDeleteAccount} className="btn btn-outline-danger"
                                        type="button">
                                    {t('delete_account')}
                                </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Account;
