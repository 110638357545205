function createAlert(message, type = 'info', duration = 5000) {
    const alertContainer = document.getElementById('alert-container');
    if (!alertContainer) return;

    const alert = document.createElement('div');
    alert.className = `alert alert-${type} alert-dismissible fade show`;
    alert.role = 'alert';
    alert.style.width = '100%';
    alert.innerHTML = `
        ${message}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    `;

    alertContainer.appendChild(alert);

    let timeoutId = null;

    const closeAlert = () => {
        alert.classList.remove('show');
        setTimeout(() => alert.remove(), 150);
    };

    const startTimer = () => {
        if (duration > 0) {
            timeoutId = setTimeout(closeAlert, duration);
        }
    };

    const stopTimer = () => {
        clearTimeout(timeoutId);
    };

    startTimer();

    alert.addEventListener('mouseover', stopTimer);
    alert.addEventListener('mouseout', startTimer);

    alert.querySelector('.btn-close').addEventListener('click', closeAlert);
}

export default createAlert;
