import React, { useEffect } from 'react';
import { Tooltip } from 'bootstrap';

const HelpButton = ({
                        buttonType = "button",
                        buttonClass = "btn",
                        buttonText = <i className="fa-solid fa-circle-info"></i>,
                        tooltipText = "Texto de ayuda",
                        placement = "bottom"
                    }) => {
    useEffect(() => {
        // Asegura que los tooltips se inicialicen
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

        // Limpieza al desmontar el componente
        return () => {
            tooltipList.forEach(tooltip => tooltip.dispose());
        };
    }, []);

    return (
        <button type={buttonType} className={`btn ${buttonClass}`} data-bs-toggle="tooltip" data-bs-placement={placement} title={tooltipText}>
            {buttonText}
        </button>
    );
};

export default HelpButton;
