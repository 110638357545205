import React, { useState, useEffect } from 'react';
import { t } from "i18next";

function TypingAnimation() {
    const phrases = t('typing_phrases', { returnObjects: true });
    const [index, setIndex] = useState(Math.floor(Math.random() * phrases.length));
    const [subIndex, setSubIndex] = useState(0);
    const [reverse, setReverse] = useState(false);
    const [blink, setBlink] = useState(true);

    const typingSpeed = 200;
    const deletingSpeed = 50;

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            if (subIndex === phrases[index].length + 1 && !reverse) {
                setReverse(true);
            } else if (subIndex === 0 && reverse) {
                setReverse(false);
                let newIndex;
                do {
                    newIndex = Math.floor(Math.random() * phrases.length);
                } while (newIndex === index);
                setIndex(newIndex);
            } else {
                setSubIndex(prevSubIndex => prevSubIndex + (reverse ? -1 : 1));
            }
        }, reverse ? deletingSpeed : typingSpeed);

        return () => clearTimeout(typingTimeout);
    }, [subIndex, index, reverse, phrases]);

    useEffect(() => {
        const cursorTimeout = setTimeout(() => {
            setBlink(prevBlink => !prevBlink);
        }, 500);

        return () => clearTimeout(cursorTimeout);
    }, [blink]);

    return (
        <span>
            {phrases[index]?.substring(0, subIndex)}
            <span style={{ opacity: blink ? 1 : 0, fontWeight: 'bold' }}>|</span>
        </span>
    );
}

export default TypingAnimation;
