import axios from 'axios';

class ApiService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        // Configura aquí tus encabezados predeterminados
        this.headers = {
            'Content-Type': 'application/json'
        };
    }

    // Método para obtener datos
    async getData(endpoint) {
        try {
            const response = await axios.get(`${this.baseUrl}/${endpoint}`, {
                headers: this.headers
            });
            return response.data;
        } catch (error) {
            console.error('Error al obtener datos:', error);
            throw error;
        }
    }

    // Método para enviar datos
    async postData(endpoint, data) {
        try {
            const response = await axios.post(`${this.baseUrl}/${endpoint}`, data, {
                headers: this.headers
            });
            return response.data;
        } catch (error) {
            console.error('Error al enviar datos:', error);
            throw error;
        }
    }

    // Método para actualizar datos
    async updateData(endpoint, data) {
        try {
            const response = await axios.put(`${this.baseUrl}/${endpoint}`, data, {
                headers: this.headers
            });
            return response.data;
        } catch (error) {
            console.error('Error al actualizar datos:', error);
            throw error;
        }
    }

    // Método para eliminar datos
    async deleteData(endpoint) {
        try {
            const response = await axios.delete(`${this.baseUrl}/${endpoint}`, {
                headers: this.headers
            });
            return response.data;
        } catch (error) {
            console.error('Error al eliminar datos:', error);
            throw error;
        }
    }
}

export default ApiService;
