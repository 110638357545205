import React, {useState} from 'react';
import Beneficiary from '../assets/beneficiary.webp';
import Account from '../assets/account.webp';
import Payment from '../assets/payment.webp';
import Settlements from '../assets/settlements.webp';
import {t} from "i18next";

function CardSwitcher() {
    const cards = [
        {
            id: 1,
            title: 'actors',
            content: 'how_work_actor',
            img: {
                src: Beneficiary,
                alt: 'actors'
            }
        },
        {
            id: 2,
            title: 'account',
            content: 'how_work_account',
            img: {
                src: Account,
                alt: 'account'
            }
        },
        {
            id: 3,
            title: 'payers',
            content: 'payers_msj',
            img: {
                src: Payment,
                alt: 'payments'
            }
        },
        {
            id: 4,
            title: 'beneficiaries',
            content: 'beneficiaries_msj',
            img: {
                src: Payment,
                alt: 'payments'
            }
        },
        {
            id: 5,
            title: 'settlement',
            content: 'how_work_settlement',
            img: {
                src: Settlements,
                alt: 'settlement'
            }
        },
        {
            id: 6,
            title: 'how_work_share_the_link',
            content: 'how_work_share_the_link_msj',
            img: {
                src: Settlements,
                alt: 'how_work_share_the_link'
            }
        },
    ];

    const [activeCard, setActiveCard] = useState(cards[0]);

    return (
        <div className="row">
            <div className="d-none d-sm-flex col-4 align-items-center">
                <div className={"w-100"}>
                    <h2 className={"mb-3 p"}>{t('how_work')}</h2>
                    <div className={"d-flex flex-column"}>
                        {cards.map((card) => (
                            <button
                                key={card.id}
                                type="button"
                                className={`btn btn-link text-decoration-none text-start text-capitalize h3 ps-0 ${card.id === activeCard.id ? "active" : "text-black-50"}`}
                                onClick={() => setActiveCard(card)}>
                                {t(card.title)}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="d-none d-sm-block col-sm-8">
                <div className="card">
                    <img src={activeCard.img.src} alt={t(activeCard.img.alt)} className="card-img-top"/>
                    <div className="card-body bg-muted py-4">
                        <h5 className="card-title text-capitalize">{t(activeCard.title)}</h5>
                        <p className="card-text my-4">{t(activeCard.content)}</p>
                    </div>
                </div>
            </div>
            <div className="d-block d-sm-none col-12">
                <h2 className={"mb-3"}>{t('how_work')}</h2>
                {cards.map((card) => (
                    <div key={card.id} className={"card mb-3"}>
                        <img src={card.img.src} alt={t(card.img.alt)} className="card-img-top"/>
                        <div className="card-body bg-muted py-4">
                            <h5 className="card-title text-capitalize">{t(card.title)}</h5>
                            <p className="card-text my-4">{t(card.content)}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CardSwitcher;
