import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {analytics} from '../../config/firebase/firebase-analytics';
import {t} from "i18next";
import {logEvent} from "firebase/analytics";
import LoadingScreen, {useLoading} from "../../components/LoadingScreen";
import createAlert from "../../components/AlertComponent";
import HelpButton from "../../components/HelpButton";
import {formatDate} from "../../components/misc";
import ApiService from "../../components/ApiService";

function Payment() {
    const { payment_id } = useParams();
    const [account_id, setAccountId] = useState(null);
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [actors, setActors] = useState({});
    const [payers, setPayers] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const {showLoading, hideLoading} = useLoading();
    const [isDemo, setIsDemo] = useState(false);

    useEffect(() => {
        const accountApiService = new ApiService(process.env.REACT_APP_ACCOUNT_ENDPOINT);
        const paymentApiService = new ApiService(process.env.REACT_APP_PAYMENT_ENDPOINT);

        const fetchPayment = async () => {
            if (payment_id) {
                const data = await paymentApiService.getData('payment/' + payment_id);
                if (data) {
                    setDate(formatDate(new Date(data.date)));
                    setDescription(data.description);
                    setAmount(Number(data.amount));
                    setPayers(data.payers || []);
                    setBeneficiaries(data.beneficiaries || []);
                    setAccountId(data.accountId);
                } else {
                    console.log(t('no_such_payment'));
                }
            } else {
                setDate(formatDate(new Date()));
                setAccountId(localStorage.getItem('account_id'))
            }

            return account_id;
        };

        const fetchActors = async (accountId) => {
            if (accountId) {
                const data = await accountApiService.getData('account/'+accountId);
                if (data && data.actors) {
                    setActors(data.actors);
                    setIsDemo(data.isDemo);
                } else {
                    console.log('No actors found');
                }
            }
        };

        setIsLoading(true);
        fetchPayment().then(() => {
            if (account_id) {
                setAccountId(account_id);
                fetchActors(account_id).finally(() => {
                    setIsLoading(false);
                });
            }
        });
    }, [account_id, payment_id]);


    const handleSubmit = async (e) => {
        if (isDemo) {
            return false;
        }
        e.preventDefault();

        // Validation to ensure at least one payer and one beneficiary
        if (payers.length === 0 || beneficiaries.length === 0) {
            createAlert(t('select_min_payment'), "warning", 3000);
            return;
        }

        const paymentData = {
            date: new Date(date),
            description,
            amount: Number(amount),
            payers,
            beneficiaries,
            accountId: account_id
        };

        const paymentApiService = new ApiService(process.env.REACT_APP_PAYMENT_ENDPOINT);

        try {
            if (!payment_id) {
                showLoading('action');
                await paymentApiService.postData('payment/', paymentData);
                logEvent(analytics,'create_payment', {
                    description: 'User create a new payment'
                });

                createAlert(t('payment_create_success'), "success", 3000);
            } else {
                showLoading('update');
                await paymentApiService.updateData('payment/' + payment_id, paymentData);
                logEvent(analytics,'update_payment', {
                    description: 'User update payment'
                });

                createAlert(t('payment_update_success'), "success", 3000);
            }
            navigate(`/a/${account_id}`);
        } catch (error) {
            console.error(t('payment_save_error'), error);
            if (!payment_id) {
                logEvent(analytics,'error_create_payment', {
                    description: error
                });
            } else {
                logEvent(analytics,'error_update_payment', {
                    description: error
                });
            }

            createAlert(t('payment_save_error_msj'), "warning", 3000);
        } finally {
            hideLoading();
        }
    };

    const handleCheckboxChange = (actor, list, setList) => {
        if (list.includes(actor)) {
            setList(list.filter(item => item !== actor));
        } else {
            setList([...list, actor]);
        }
    };

    if (isLoading) {
        return <LoadingScreen/>;
    }

    return (
        <div className="container">
            <div className="my-5 w-100">
                <h2 className="mb-3">
                    <Link to={`/a/${account_id}`} className="btn btn-link me-2">
                        <i className="fa-solid fa-arrow-left"></i>
                    </Link>
                    {!payment_id ? t('create_payment') : t('edit_payment')}
                </h2>
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="date" className="form-label">{t('date')}</label>
                                <input type="datetime-local" className="form-control numbers" id="date" value={date}
                                       onChange={e => setDate(e.target.value)} required/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">{t('description')}</label>
                                <input type="text" className="form-control" id="description" value={description}
                                       placeholder={t('payment_description_ej')}
                                       onChange={e => setDescription(e.target.value)} required/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="amount" className="form-label">{t('amount')}</label>
                                <input type="number" className="form-control numbers" id="amount" value={amount}
                                       placeholder={t('payment_amount_ej')}
                                       onChange={e => setAmount(e.target.value)} required/>
                            </div>
                            {account_id !== null ? (
                                <>
                                    <hr className="my-3"/>
                                    <div className="mb-1">
                                        <p className="form-label">
                                            <span className="w-100">{t('payers')} <HelpButton
                                                tooltipText={t('payers_msj')}/></span>
                                        </p>
                                        <div className="d-flex flex-wrap">
                                            {Object.entries(actors).map(([actor, data]) => (
                                                <div key={actor} className="me-2 mb-2">
                                                    <input type="checkbox" className="btn-check" id={`payer-${actor}`}
                                                           checked={payers.includes(actor)}
                                                           onChange={() => handleCheckboxChange(actor, payers, setPayers)}/>
                                                    <label className="btn btn-outline-secondary"
                                                           htmlFor={`payer-${actor}`}>{data['name']}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="mb-1">
                                        <p className="form-label">
                                            <span className="w-100">{t('beneficiaries')} <HelpButton
                                                tooltipText={t('beneficiaries_msj')}/></span>
                                        </p>
                                        <div className="d-flex flex-wrap">
                                            {Object.entries(actors).map(([actor, data]) => (
                                                <div key={actor} className="me-2 mb-2">
                                                    <input type="checkbox" className="btn-check"
                                                           id={`beneficiary-${actor}`}
                                                           checked={beneficiaries.includes(actor)}
                                                           onChange={() => handleCheckboxChange(actor, beneficiaries, setBeneficiaries)}/>
                                                    <label className="btn btn-outline-secondary"
                                                           htmlFor={`beneficiary-${actor}`}>{data['name']}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ) : ''}
                            <hr className="mb-4 bt-3 mt-2"/>
                            {
                                isDemo ? (
                                    <button type="button"
                                            className="btn btn-success w-100"
                                            disabled>{!payment_id ? t('create_payment') : t('edit_payment')}</button>
                                ) : (
                                    <button type="submit"
                                            className="btn btn-success w-100">{!payment_id ? t('create_payment') : t('edit_payment')}</button>
                                )
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;
