import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import CreateAccount from './views/account/CreateAccount';
import Account from './views/account/Account';
import HomePage from './views/HomePage';
import Payment from './views/payment/Payment';
import PrivacyPolicy from "./views/PrivacyPolicy";
import Header from "./views/Header";
import Footer from "./views/Footer";
import {useTranslation} from "react-i18next";

function App() {
    useTranslation();

    return (
        <BrowserRouter>
            <Header/>
            <main>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/a/new" element={<CreateAccount/>}/>
                    <Route path="/a/:account_id" element={<Account/>}/>
                    <Route path="/p/:payment_id" element={<Payment/>}/>
                    <Route path="/p/new" element={<Payment/>}/>
                    <Route path="/privacy_policy" element={<PrivacyPolicy/>}/>
                </Routes>
            </main>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
