import {Link} from 'react-router-dom';
import {t} from "i18next";
import {analytics} from "../config/firebase/firebase-analytics";
import {logEvent} from 'firebase/analytics';
import React from "react";
import TypingAnimation from "../components/TypingAnimation";
import CardSwitcher from "../components/CardSwitcher";
import BettyNotice from '../assets/BettyMeme.webp';
import {useTrackScrollDepth} from "../components/AnalyticsProvider";

function HomePage() {
    const demo_id = process.env.REACT_APP_DEMO_ID;
    useTrackScrollDepth();
    const handleCreateAccount = () => {
        logEvent(analytics, 'go_to_create_account_home_click', {
            description: 'User clicked the create account button'
        });
    };

    return (
        <>
            <div className="container">
                <div id={"banner"} className="my-5 py-5 text-center">
                    <h2>{t('banner_header')}<br/><TypingAnimation/></h2>
                    <p className={"mb-3"}>{t('banner_subtext')}</p>
                    <Link to={"/a/new"} className={"btn btn-primary"}
                          onClick={handleCreateAccount}>{t('banner_action')}</Link>
                    <p className={"mt-3"}>{t('is_free_1')}</p>
                </div>
                <div id={"how_work"} className={"py-5"}>
                    <CardSwitcher/>
                </div>
                <div className="py-5 text-center" id={"demo"}>
                    <h4 className={"mb-3"}>{t('check_demo_account')}</h4>
                    <Link className={"btn btn-secondary"} to={"/a/" + demo_id}>{t('go_to_demo')}</Link>
                    <p className={"mt-3"}>{t('is_free_2')}</p>
                </div>
            </div>
            <div className="bg-muted py-5" id={"notice"}>
                <div className="container">
                    <h2 className={"mb-3"}>{t('notice_title')}</h2>
                    <img src={BettyNotice} alt={t('notice_alt')} className={"w-100"}/>
                </div>
            </div>
        </>
    );
}

export default HomePage;
